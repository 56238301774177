@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 640px) {
    footer {
        flex-direction: column;
        align-items: center;
    }

    .menu-section {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 440px) {
        .app-name {
            font-size: 1.75rem;
        }
        .section-text {
            font-size: 1.25rem;
        }
        .logo-container {
            gap: 6rem;
        }
}

@media screen and (max-width: 768px) {
    .logo-container {
        gap: 5rem;
    }
}

.customBox {
    background-color: azure;
  
    padding: 2rem;
    

    border-radius: 20px;
  
    /* min-height: 100%; */
  
    display: flex;
    flex-direction: column;

  }
  
  .customBox img {
    width: 100%;
    height: 100%;
  
    border-radius: 20px;
  }

  .custom-box-label {
    padding-bottom: 1rem;
    align-self: flex-start;
  }

  .MuiModal-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiBox-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

